<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-6">
        <div class="page-title-box">
          <h2 class="">Rankings</h2>
        </div>
      </div>
      <div class="col-6">
        <div class="float-right">
          <select @change="loadRank" v-model="rankOption" class="form-control">
            <option
              v-for="option in rankOptions"
              :key="option.id"
              :value="option.id"
              >{{ option.selectName }}</option
            >
          </select>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row mb-md-2">
          <h3 class="mx-3 mb-3">
            Top {{ tableData.length }} - {{ selectedRankOption.headerName }}
          </h3>
        </div>
        <b-table
          :items="tableData"
          :fields="fields"
          responsive="sm"
          :striped="true"
          :head-variant="'dark'"
        >
          <template v-slot:cell(name)="data">
            <router-link
              :to="{
                name: 'profile',
                params: { username: data.item.username },
              }"
            >
              {{ data.item | name }}
            </router-link>
          </template>
          <template v-slot:cell(username)="data">
            <router-link
              :to="{
                name: 'profile',
                params: { username: data.item.username },
              }"
            >
              @{{ data.value }}
            </router-link>
          </template>
          <template v-slot:cell(value)="data">
            {{
              selectedRankOption.format
                ? selectedRankOption.format(data.value)
                : data.value
            }}
          </template>
        </b-table>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";

import { mapState } from "vuex";

import * as api from "@/api";

export default {
  page: {
    title: "Rankings",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
  },
  data() {
    return {
      rankOption: "totalPoints",
      rankOptions: [
        {
          id: "totalPoints",
          headerName: "Ranking Global",
          columnName: "Nº de pontos",
          selectName: "Ranking global",
        },
        {
          id: "issuesSolved",
          headerName: "Maiores anjos",
          columnName: "Nº de suporte completos",
          selectName: "Maiores anjos",
        },
        {
          id: "reviewsWithText",
          headerName: "Maiores analistas por escrito",
          columnName: "Nº de revisões",
          selectName: "Maiores analistas por escrito",
        },
        {
          id: "reviewsWithAudio",
          headerName: "Maiores analistas por áudio",
          columnName: "Nº de revisões",
          selectName: "Maiores analistas por áudio",
        },
        {
          id: "reviewsWithVideo",
          headerName: "Maiores analistas por vídeo",
          columnName: "Nº de revisões",
          selectName: "Maiores analistas por vídeo",
        },
        {
          id: "activeDays",
          headerName: "Mais assíduos",
          columnName: "Nº de dias online",
          selectName: "Mais assíduos",
        },
        {
          id: "friendsInvited",
          headerName: "Mais influencers",
          columnName: "Nº de amigos convidados",
          selectName: "Mais influencers",
        },
        {
          id: "bestCorrectAnswerStreak",
          headerName: "Usuários com maiores sequências de acertos",
          columnName: "Nº de questões certas seguidas",
          selectName: "Maiores sequências de acertos",
        },
        {
          id: "correctAnswers",
          headerName: "Maiores acertadores de questões",
          columnName: "Nº de questões",
          selectName: "Maiores acertadores de questões",
        },
        {
          id: "bestCorrectionRateInSession",
          headerName:
            "Usuários com maiores taxas de acertos em questões por sessão",
          columnName: "% Taxa de acerto",
          selectName: "Maiores taxas de acerto por sessão",
          format: (x) => `${x}%`,
        },
        {
          id: "answersInHolydays",
          headerName: "Maiores resolvedores em feriados",
          columnName: "Nº de questões",
          selectName: "Maiores resolvedores em feriados",
        },
        {
          id: "reactionCounts",
          headerName: "Análises melhor avaliadas",
          columnName: "Nº de reações",
          selectName: "Análises melhor avaliadas",
        },
      ],
      tableData: [],
    };
  },

  created() {
    this.loadRank();
  },

  computed: {
    ...mapState("auth", ["currentUser"]),

    selectedRankOption() {
      return this.rankOptions.find(
        (rankOption) => rankOption.id === this.rankOption
      );
    },

    fields() {
      return [
        { key: "position", label: "Posição", sortable: false },
        { key: "name", label: "Nome", sortable: false },
        { key: "username", label: "Nome de usuário", sortable: false },
        {
          key: "value",
          label: this.selectedRankOption.columnName,
          sortable: false,
        },
      ];
    },
  },

  methods: {
    loadRank() {
      api
        .getUsers(
          {
            direction: "desc",
            addBadges: true,
            order: this.selectedRankOption.id,
            limit: 10,
            participateInRankings: true,
          },
          true
        )
        .then((users) => {
          this.tableData = users.map((u, i) => ({
            ...u,
            position: i + 1,
            value: u[this.selectedRankOption.id],
          }));
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao carregar o ranking!", "error");
        });
    },
  },

  watch: {
    rankOption: "loadRank",
  },
};
</script>
