var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"page-title-box"},[_c('h2',{},[_vm._v("Rankings")])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"float-right"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.rankOption),expression:"rankOption"}],staticClass:"form-control",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.rankOption=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.loadRank]}},_vm._l((_vm.rankOptions),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(_vm._s(option.selectName))])}),0)])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-md-2"},[_c('h3',{staticClass:"mx-3 mb-3"},[_vm._v(" Top "+_vm._s(_vm.tableData.length)+" - "+_vm._s(_vm.selectedRankOption.headerName)+" ")])]),_c('b-table',{attrs:{"items":_vm.tableData,"fields":_vm.fields,"responsive":"sm","striped":true,"head-variant":'dark'},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('router-link',{attrs:{"to":{
              name: 'profile',
              params: { username: data.item.username },
            }}},[_vm._v(" "+_vm._s(_vm._f("name")(data.item))+" ")])]}},{key:"cell(username)",fn:function(data){return [_c('router-link',{attrs:{"to":{
              name: 'profile',
              params: { username: data.item.username },
            }}},[_vm._v(" @"+_vm._s(data.value)+" ")])]}},{key:"cell(value)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.selectedRankOption.format ? _vm.selectedRankOption.format(data.value) : data.value)+" ")]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }